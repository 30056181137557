import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ScrollerAnimation {
    constructor() {
        const wrapper = document.querySelectorAll('.scroller');

        if (!wrapper.length) {
            return;
        }

        this.init();
    }

    init() {

        const options: ScrollTrigger.StaticVars = {
            trigger: '.scene__featured-scroller',
            start: '-=20% top',
            // pinSpacing: false,
            pin: true,
            // end: 'bottom center',
            // markers: true,
            preventOverlaps: true,
        };

        ScrollTrigger.create(options);

        gsap.fromTo(
            '.scroller img',
            { x: '100vw' },
            {
                x: 0,
                scrollTrigger: {
                    trigger: '.scroller',
                    scrub: 1,
                    // end: 'bottom 100',
                    // start: 'bottom bottom',
                    // markers: true,
                    // start: "top top",
                    // end: "+=200%",
                },
                stagger: {
                    amount: 0.1,
                },
            }
        );
    }
}

document.addEventListener('DOMContentLoaded', () => new ScrollerAnimation());
